
import { Component, Vue } from "vue-property-decorator";
@Component
export default class EnquireCards extends Vue {
  //data
  loading = true;
  isActive: number | null = 0;
  currentIndex = 2; //imgs正中間的index
  centerInfo: { id: string; index: number; cover: string }; // 当前中间信息
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  imgs: {
    id: string;
    index: number;
    cover: string;
    title: string;
    desc: string;
    logosrc: string;
    logotag: string;
    texttag: string;
  }[] = [
    {
      id: "1",
      index: 0,
      cover: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/img/010.jpg"),
      title: "凱撒衛浴x師虎來了 合作培訓技師",
      desc: "學習基礎凱撒衛浴的基礎安裝過程與維修方法,了解安裝五金件及工具使用方式。",
      logosrc: require("../../../../public/fdtiger-offical-site-img/tigerschool/home/logo/002.png"),
      logotag: "凱撒衛浴",
      texttag: "凱撒衛浴",
    },
    {
      id: "2",
      index: 1,
      cover: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/img/013.jpg"),
      title: "和成衛浴x師虎來了 合作培訓技師",
      desc: "透過課程學習到各種水設施原理與種類，現場並實際操作。根據不同的現場狀況做出專業判斷。",
      logosrc: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/icon/web/007.png"),
      logotag: "和成認證",
      texttag: "和成衛浴",
    },
    {
      id: "3",
      index: 2,
      cover: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/img/009.jpg"),
      title: "職訓局x師虎來了 合作培訓技師",
      desc: "師虎來了與臺北市職能發展學院合作，在台北市開課。希望能將有更多時段供各位求職者學習。",
      logosrc: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/icon/web/006.png"),
      logotag: "職訓局",
      texttag: "職訓局",
    },
    {
      id: "4",
      index: 3,
      cover: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/img/013.jpg"),
      title: "和成衛浴x師虎來了 合作培訓技師",
      desc: "透過課程學習到各種水設施原理與種類，現場並實際操作。根據不同的現場狀況做出專業判斷。",
      logosrc: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/icon/web/007.png"),
      logotag: "和成認證",
      texttag: "和成衛浴",
    },
    {
      id: "5",
      index: 4,
      cover: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/img/009.jpg"),
      title: "職訓局x師虎來了 合作培訓技師",
      desc: "師虎來了與臺北市職能發展學院合作，在台北市開課。希望能將有更多時段供各位求職者學習。",
      logosrc: require("../../../../public/fdtiger-offical-site-img/tigerschool/enquiretraining/icon/web/006.png"),
      logotag: "職訓局",
      texttag: "職訓局",
    },
  ];
  config5: any = [
    {
      id: "A",
      position: "absolute",
      width: "45%",
      top: "19.2%",
      left: "0%",
      opacity: 0,
      zIndex: 0,
      transition: ".4s",
    },
    {
      id: "B",
      position: "absolute",
      width: "45%",
      top: "14%",
      left: "-26%",
      opacity: 1,
      zIndex: 1,
      transition: ".4s",
    },
    {
      id: "center",
      position: "absolute",
      width: "45%",
      top: "6%",
      left: "45%",
      marginLeft: "-16.5%",
      opacity: 1,
      zIndex: 2,
      transition: ".4s",
    },
    {
      id: "D",
      position: "absolute",
      width: "45%",
      top: "14%",
      left: "87%",
      opacity: 1,
      zIndex: 1,
      transition: ".4s",
    },
    {
      id: "E",
      position: "absolute",
      width: "45%",
      top: "19.2%",
      left: "78%",
      opacity: 0,
      zIndex: 0,
      transition: ".4s",
    },
  ];
  configtext: any = [
    {
      id: "A",
      display: "none",
      zIndex: 0,
      transition: ".4s",
    },
    {
      id: "B",
      display: "none",
      zIndex: 1,
      transition: ".4s",
    },
    {
      id: "center",
      display: "block",
      zIndex: 2,
      transition: ".4s",
    },
    {
      id: "D",
      display: "none",
      zIndex: 1,
      transition: ".4s",
    },
    {
      id: "E",
      display: "none",
      zIndex: 0,
      transition: ".4s",
    },
  ];
  interval: number;
  //hook
  created(): void {
    this.centerCard(); //get center card info
    this.addCardStyle(); //add position style
  }

  prev(): void {
    // this.imgs.unshift(this.imgs.pop());
    this.config5.push(this.config5.shift());
    this.configtext.push(this.configtext.shift());
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.imgs.length - 1;
    }
    this.centerCard();
    this.centerIndex("prev");
  }

  next(): void {
    // this.imgs.push(this.imgs.shift());
    this.config5.unshift(this.config5.pop());
    this.configtext.unshift(this.configtext.pop());
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex > this.imgs.length - 1) {
      this.currentIndex = 0;
    }
    this.centerCard();
    this.centerIndex("next");
  }
  page(index: any): void {
    this.config5.unshift(this.config5.pop(index));
    this.configtext.unshift(this.configtext.pop(index));
    this.isActive = index;
    this.centerCard();
  }

  // 畫面imgs在位置上的index
  centerIndex(val: string): void {
    if (val == "prev") {
      for (let val of this.imgs) {
        if (val.index == this.imgs.length - 3) {
          val.index = 0;
        } else {
          val.index = val.index + 1;
        }
        this.isActive = val.index;
      }
    } else {
      for (let val of this.imgs) {
        if (val.index == 0) {
          val.index = this.imgs.length - 3;
        } else {
          val.index = val.index - 1;
        }
        this.isActive = val.index;
      }
    }
  }

  centerCard(): void {
    this.centerInfo = this.imgs[this.currentIndex];
    this.$emit("centerInfo", this.centerInfo);
  }

  addCardStyle(): void {
    if (this.imgs.length > 5) {
      let addtime = this.imgs.length - 5;
      for (let i = 0; i < addtime; i++) {
        this.config5.push({
          id: "center",
          position: "absolute",
          width: "45%",
          height: "100%",
          top: "0px",
          left: "50%",
          marginLeft: "-22.5%",
          opacity: 0,
          zIndex: 2,
          transition: ".1s",
        });
      }
      this.configtext.push({
        id: "center",
        diplay: "block",
        zIndex: 2,
        transition: ".1s",
      });
    }
  }
}
